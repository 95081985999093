<template>
  <div id="commonPagnation" class="clearfix">
    <a-divider v-if="placement !== 'right'"></a-divider>
    <div v-if="placement === 'right'" class="pull-left">
      共{{ totalNum }}条，每页 {{ pageSize }} 条
    </div>
    <a-pagination
      background
      layout="prev, pager, next"
      :class="{ 'pull-right': placement === 'right' }"
      :default-current="pageNum"
      :current="pageNum"
      :page-size="pageSize"
      :total="totalNum"
      size="mini"
      @change="changePage">
    </a-pagination>
  </div>
</template>

<script>
import merge from "webpack-merge"
export default {
  props: {
    pageNum: Number,
    pageSize: Number,
    totalNum: Number,
    placement: String // 默认居中；也可以设置等于right
  },
  mounted() {
    if(this.$route.name != 'personCenter') {
      if(!this.$route.query.pager) {
        this.$router.replace({
          query: merge(this.$route.query, { pager: 1 }),
        });
      }else {
        let loginIn = 0;
        this.$API.ForntUserDetail().then(res => {
          loginIn =  res.data.code == 200 ? 1 : 0;
          if(this.$route.query.pager >= 2 && !loginIn) {
            this.$store.commit('open_type', 1);
            this.$parent.pageNum = 1;
            if(this.$route.name != 'standardInformationBase') this.$parent.search = false;
            this.$parent.showPage();
            this.$router.replace({
              query: merge(this.$route.query, { pager: 1 }),
            });
            return;
          }
        })
      }
    }
  },
  methods: {
    changePage(page) {
      if(page >= 2 && !this.$store.state.loginIn) {
        this.$store.commit('open_type', 1);
        this.$parent.pageNum = 1;
        return;
      }
      this.$parent.pageNum = page;
      this.$router.replace({
        query: merge(this.$route.query, { pager: page }),
      });
      this.$parent.showPage();
    }
  }
}
</script>

<style lang='scss'>
  #commonPagnation {
    padding: 30px 10px;

    .el-divider--horizontal {
      margin: 30px 0;
    }

    .pull-left {
      line-height: 20px;
      margin: 6px 0;
      font-size: 12px;
    }

    .arco-pagination-size-mini .arco-pagination-item {
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      background: #f4f4f5;
    }
    .arco-pagination-item:not(:last-child) {
      margin-right: 10px;
    }
    .arco-pagination-item-active, 
    .arco-pagination-item-active:hover,
    .arco-pagination-item:hover {
      background: #05A081!important;
      color: #fff;
    }
  }
</style>
